.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FD2D01;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border: 3px solid #FFD7D7;
    box-shadow: 3px 3px 8px #444444;
    -webkit-box-shadow: 3px 3px 8px #444444;
    -moz-box-shadow: 3px 3px 8px #444444;
    cursor: pointer;
}

.inputfile+label:hover {
    padding: 10px;
    color: #4E5166;
    background-color: #FFD7D7;
    border: 3px solid #FD2D01;
    box-shadow: 1px 1px 3px #4E5166;
    -webkit-box-shadow: 1px 1px 3px #4E5166;
    -moz-box-shadow: 1px 1px 3px #4E5166;
}

.img__send {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    width: 100%
}

.newCommentaire {
    padding: 1rem;
}

.commentaire__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.comment__img__send {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    padding-right: 1rem;
}

.newCommentaire__textarea {
    flex: 1 1 90%;
    font-size: 1rem;
    border-radius: 1rem;
    padding: 0.5rem;
}

.newCom__send {
    font-size: 2.5rem;
    color: #4E5166;
    cursor: pointer;
    position: absolute;
    right: 1rem;
}
.newCom__send:hover {
    color: #FD2D01;
}

.error {
    color: #FD2D01;
    font-size: 1rem;
}

.commentaire__content {
    display: flex;
}

.container__img {
    position: relative;
}

.image {
    max-height: 500px;
    max-width: 75%;
}

.closePreview {
    position: absolute;
    font-size: 2.5rem;
    cursor: pointer;
    color: #FD2D01;
}

@media screen and (max-width:900px) {
    .commentaire__content {
        flex-wrap: wrap;
        justify-content: center;
    }

    .newCommentaire__textarea {
        flex: 1 1 80%;
    }

    .comment__img__send {
        flex: 1 1 30%
    }
}

@media screen and (max-width:700px) {
    .newCommentaire__textarea {
        flex: 1 1 73%;
    }

    /* icon envoi */
    .newCom__send {
        font-size: 1.5rem;
    }
}