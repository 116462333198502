.article{
    margin: 2rem 0;
    width:100%;
}
.form{
    max-width: 670px;
    margin: auto;
    padding: 1rem 0;
    box-shadow: 0px 0px 10px 2px #4E5166;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
}
.field{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.field input{
    border-radius: 1rem;
    text-align: center;
    font-size:16px;
    padding:0.3rem;
    width:75%;
}
.container__inputfile{
    height: 1rem;
    padding:1rem;
}
.inputfile{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label{
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FD2D01;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border: 3px solid #FFD7D7;
    box-shadow: 3px 3px 8px #444444;
    -webkit-box-shadow: 3px 3px 8px #444444;
    -moz-box-shadow: 3px 3px 8px #444444;
    cursor: pointer;
}
.inputfile + label:hover{
    padding: 10px;
color: #4E5166;
background-color: #FFD7D7;
border: 3px solid #FD2D01;
box-shadow: 1px 1px 3px #4E5166;
-webkit-box-shadow: 1px 1px 3px #4E5166;
-moz-box-shadow: 1px 1px 3px #4E5166;
}
.image{
    max-width:200px;
    padding:1rem
}
.error{
    color:#FD2D01;
    padding:1rem;
}
.container__img{
    position: relative;
}
.closePreview {
    position: absolute;
    font-size: 2.5rem;
    left:0;
    cursor: pointer;
    color: #FD2D01;
}

@media screen and (max-width: 700px){
    .form{
        min-width: 100%;
        padding: 1rem 0;
        margin:0;
        border-radius: 0rem;
    }
    .field input{
        width:90%;
    }
}