.welcome{
    display: flex;
    align-items: center;
    padding:1rem;
    box-shadow: 0px 0px 10px 2px #4E5166;
    border-radius: 1rem;
    margin:2rem auto 1rem;
    font-size:1.8rem;
    color:#4E5166;
}

/* icon modify post */
.iconModify{
    font-size:2.5rem;
    color:#4E5166;
}
.iconModify:hover{
    font-size:2.8rem;
}

/* icon delete */
.iconDelete{
    font-size:2.5rem;
    color:#4E5166;
}
.iconDelete:hover{
    font-size:2.8rem;
}
/* icon close welcome */
.closeWelcome{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
}
.closeWelcome:hover{
    color:#FD2D01;

}

@media screen and (max-width:700px) {
    .welcome{
        width:100%;
        justify-content: center;
        border-radius: 0rem;
        padding:1rem 0rem;
        margin:2rem auto 1rem;
    }
}