.commentaire__container{
    border-top: 1px solid black;
    display:flex;
    flex-direction: column;
    gap:1rem;
}
.container__likeAndCom{
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid  #4E5166;
    border-bottom: 1px solid  #4E5166;
}
.container__like{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:1rem;
    width:50%;
    border-radius: 1rem;
    cursor: pointer;

}
.container__like:hover{
    background-color:#F2F2F2;
}
.container__com{
    width:50%;
    margin:1rem;
    border-radius: 1rem;
    cursor: pointer;

}
.container__com:hover{
    background-color:#F2F2F2;

}
/* icon heart */
.heart{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
}
.heart:hover{
    color:red;
}
.heartLiked{
    font-size:2.5rem;
    color:#FD2D01;
    cursor: pointer;

}
.heartLiked:hover{
    color:#4E5166;
}

@media screen and (max-width:700px){
    /* icon heart */
    .heart{
        font-size:1.5rem;
    }
    .heartLiked{
        font-size:1.5rem;
    }
}