.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FD2D01;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border: 3px solid #FFD7D7;
    box-shadow: 3px 3px 8px #444444;
    -webkit-box-shadow: 3px 3px 8px #444444;
    -moz-box-shadow: 3px 3px 8px #444444;
    cursor: pointer;
}

.inputfile+label:hover {
    padding: 10px;
    color: #4E5166;
    background-color: #FFD7D7;
    border: 3px solid #FD2D01;
    box-shadow: 1px 1px 3px #4E5166;
    -webkit-box-shadow: 1px 1px 3px #4E5166;
    -moz-box-shadow: 1px 1px 3px #4E5166;
}

.img__send {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    width: 100%
}

.newPost__container {
    width: 100%;
}

.newPost {
    width: 75%;
    box-shadow: 0px 0px 10px 2px #4E5166;
    border-radius: 1rem;
    margin: 2rem auto;
}

.newPost__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

.newPost__textArea {
    width: 95%;
    min-height: 75px;
    border-radius: 1rem;
    font-size: 1rem;
    padding: 0.5rem;
}

.newPost__send {
    font-size: 2.5rem;
    color: #4E5166;
    cursor: pointer;
    position: absolute;
    right: 1rem;
}
.container__img{
    position: relative;
}
.image{
    max-height: 500px;
    max-width: 75%;
}
.closePreview{
    position: absolute;
    font-size: 2.5rem;
    cursor: pointer;
    color: #FD2D01;
}

.newPost__send:hover{
    color: #FD2D01;
}

.error {
    color: #FD2D01;
    font-size: 1rem;
}

@media screen and (max-width:700px) {
    .newPost {
        width: 100%;
        border-radius: 0rem;
    }

    .newPost__send {
        font-size: 1.5rem;
    }
}