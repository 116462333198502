.post__container{
    display:flex;
    flex-direction: column;
    height:100%;
    width: 75%;
    padding: 1rem 0;
    box-shadow: 0px 0px 10px 2px #4E5166;
    border-radius: 1rem;
    margin:2rem auto;
}

.post__info{
    width: 100%;
    height: 6.3rem;
    display:flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid  #4E5166;
}

.post__option{
    position: absolute;
    right:0.5rem;
    margin:0 2rem;
}

/* icon modify post */
.iconModify{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
    margin:1rem;

}
.iconModify:hover{
    color: blue;
}

/* icon delete */
.iconDelete{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
    margin:1rem;

}
.iconDelete:hover{
    color:red;
}

@media screen and (max-width:700px){
    .post__container{
        width: 100%;
        border-radius: 0rem;
    }
    /* icon modify post */
    .iconModify{
        font-size:1.5rem;
    }
    /* icon delete */
    .iconDelete{
    font-size:1.5rem;
    }
    .post__option{
        position: absolute;
        right:0.5rem;
        margin:0rem;
        display: flex;
        flex-direction: column;
    }
}