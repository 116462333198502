.inputfile{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label{
    text-decoration: none;
    padding: 10px;
    font-family: arial;
    font-size: 1.2em;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FD2D01;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border: 3px solid #FFD7D7;
    box-shadow: 3px 3px 8px #444444;
    -webkit-box-shadow: 3px 3px 8px #444444;
    -moz-box-shadow: 3px 3px 8px #444444;
    cursor: pointer;
}
.inputfile + label:hover{
padding: 10px;
color: #4E5166;
background-color: #FFD7D7;
border: 3px solid #FD2D01;
box-shadow: 1px 1px 3px #4E5166;
-webkit-box-shadow: 1px 1px 3px #4E5166;
-moz-box-shadow: 1px 1px 3px #4E5166;
}
.form__container{
    width:100%
}
.form{
    display:flex;
    align-items: center;
    flex:1 1;
    justify-content: center;
    gap:1rem;
    margin:1rem;
}
.input_img_send{
    display:flex;
    align-items: center;
    gap:1rem;
}
.img_and_send{
    display: flex;
    align-items: center;
}
.modifyPost__textarea{
    flex:1 1 75%;
    border-radius: 1rem;
    font-size: 1rem;
    padding:0.5rem;

}
.modify__send{
    color:#4E5166;
    font-size:2.5rem;
    cursor:pointer;
}
.modify__send:hover{
    color:#FD2D01;
}
.error{
    color: #FD2D01;
}
.container__img {
    position: relative;
}
.closePreview {
    position: absolute;
    font-size: 2.5rem;
    cursor: pointer;
    color: #FD2D01;
}

@media screen and (max-width:900px){
    .form{
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .modify__img__send{
        flex:1 1 30%;
        justify-content: space-around;
    }
}

@media screen and (max-width:700px){
    .modifyPost__send{
        font-size:1.5rem
    }
    .form{
        justify-content: center;
    }
    .input_img_send{
        width: 100%;
        /* margin: auto; */
        justify-content: center;
    }
    .modifyPost__textarea{
        flex:1 1 100%;
    }
    .modify__send{
        font-size:1.5rem;
    }
}