@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body{
    background-color: #FFD7D7;
    margin:0;
    padding:0;
    font-family: 'Lato', sans-serif;
}
#root{
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    z-index: -2;
}