.container__description{
width:100%
}
.img{
    max-height: 500px;
    max-width: 100%;
}
.description{
    text-align:start;
    margin: 1rem 2.5rem;
}
.container__img{
    border-top: 1px solid  #4E5166;
    border-bottom: 1px solid  #4E5166;
}