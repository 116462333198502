.post__info__user{
    display:flex;
    position: absolute;
    top:0.5rem;
    left:0.5rem;
    margin:0 2rem;
    align-items: center;
}

@media screen and (max-width:700px){
    .post__info__user{
        margin-left:0rem;
        margin-right: 3rem;
    }
}