.container__msg{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100vw;
  height: 65vh;
  position:relative;
}
.container__p{
    font-size:8rem
}
.container__img{
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  animation: container__img 2s linear infinite;
  
}

@media screen and (max-width:700px){
  .container__p{
      font-size:4rem
  }
  .container__img{
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      animation: container__img 2s linear infinite;
  
  }
  
}
@keyframes container__img {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}