.article{
    margin: 2rem 0;
    width:100%;
}
.form{
    max-width: 670px;
    margin: auto;
    padding: 1rem 0;
    box-shadow: 0px 0px 10px 2px #4E5166;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
}
.field{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.field input{
    border-radius: 1rem;
    text-align: center;
    font-size:16px;
    padding:0.3rem;
    width:75%;
}
.error{
    color:#FD2D01;
    padding:1rem;
}
@media screen and (max-width: 700px){
    .form{
        min-width: 100%;
        padding: 1rem 0;
        margin:0;
        border-radius: 0rem;
    }
    .field input{
        width:90%;
    }
}