.commentaire__old{
    display:flex;
    padding: 0.5rem 1rem;
    position: relative;
    align-items: center;
    box-shadow: 1px 1px 1px #4E5166;

    /* border: 2px solid green; */
}
.container__comment{
    position:relative;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    background-color:rgb(204, 199, 199);
    border-radius: 1rem;
    margin:auto 2rem auto 2.1rem;
    box-shadow: 0px 0px 10px 2px #4E5166;
    align-items: center;
}
.commentaire__text{
    width: 100%;
    min-height: 40px;
    text-align: left;
    padding: 0rem 1rem;
    
}
.infoUser{
    position:relative;
    width:32px;
}
/* icon heart */
.heart{
    font-size:2.5rem;
    color:#4E5166;
    position:absolute;
    right:0.8rem;
    cursor: pointer;
}
.heart:hover{
    color:#FD2D01;
}
.heartLiked{
    font-size:2.5rem;
    color:#FD2D01;
    position:absolute;
    right:0.8rem;
    cursor: pointer;
}
.heartLiked:hover{
    color:#4E5166;
}
.heart_and_count{
    position:relative;
    height: 53.5px;
    width: 50px;
}
.countLike{
    position:relative;
    top:1.5rem;
    right:2.3rem;
}
.comment{
    display: flex;
    flex-direction: column;
    flex:1 1 56%;
}
.comment>h5{
    display: flex;
    text-align: flex-start;
    margin-left:1rem;
}
.comment__option{
    display: flex;
    font-size:2.8rem;
}
.container__img{
    flex:1 1 100%;
    margin: 0.5rem;
}

.img{
    max-height: 500px;
    max-width:75%;
}
/* icon modify post */
.iconModify{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
    margin:1rem;
}
.iconModify:hover{
    color: blue;
}

/* icon delete */
.iconDelete{
    font-size:2.5rem;
    color:#4E5166;
    cursor: pointer;
    margin:1rem;

}
.iconDelete:hover{
    color:red;
}

@media screen and (max-width:700px){
    .comment__option{
        justify-content: center;
    }
    /* icon modify post */
    .iconModify{
        font-size:1.5rem;
        margin:0.5rem;
    }
    /* icon delete */
    .iconDelete{
        font-size:1.5rem;
        margin:0.5rem;

    }
    /* icon heart */
    .heart{
        font-size:1.5rem;
        top:1rem;
        right:0.3rem;
    }
    .heartLiked{
        font-size:1.5rem;
        top:1rem;
        position:absolute;
        right:0.3rem;
    }
    .countLike{
        right:1.3rem;
    }
    .container__comment{
        margin:auto 0rem auto 1.5rem;
    }
}