.footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #fd2d01 ;
    box-shadow: 0px -4px 10px 6px rgba(78,81,102,0.7);
    height:4rem; 
}
.footer__container{
    color: white;
    max-width: 1440px;
    margin: auto;
}